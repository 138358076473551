<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="companies"
          :items-per-page='-1'
          :loading="loading"
          :options.sync="options"
          disable-sort
          @update:options="handleTableUpdate($event)"
          @click:row="handleNewCompany($event.id)"
          hide-default-footer
        >
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>
          <template v-slot:[`item.cnpj`]="{ item }">
            {{ $format.cnpj(item.cnpj) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <StoreCompanyForm @store="select()" ref="StoreCompanyForm" />
  </div>
</template>

<script>
import StoreCompanyForm from "@/components/companies/forms/StoreCompanyForm.vue";

export default {
  components: { StoreCompanyForm },

  data: () => ({
    headers: [
      { text: "Código", align: "center", value: "id" },
      { text: "Name", align: "start", value: "name" },
      // { text: "Tipo de pessoa", align: "center", value: "person_type" },
      { text: "CNPJ", align: "center", value: "cnpj" },
      { text: "Status", align: "center", value: "status" },
    ],

    options: {},

    loading: false,

    searchParams: {
      page: 1,
      orderBy: "name",
      orderDirection: "asc",
    },

    companies: [],
  }),

  created() {
    this.select();
  },

  methods: {
    select(page) {
      this.searchParams.page = page;
      this.$http
        .index("company/company", this.searchParams)
        .then((response) => {
          this.companies = response.companies;
        });
    },

    handleTableUpdate(options) {
      this.options = options;
      //this.searchParams.page = options.page
      this.searchParams.orderBy = options.sortBy[0];
      this.searchParams.orderDirection = options.sortDesc[0] ? "desc" : "asc";
      this.select(options.page);
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleNewCompany(id = null) {
      if (this.$acl.can("companyEdit")) {
      this.$refs.StoreCompanyForm.open(id);
      }
    },
  },
};
</script>

<style>
</style>